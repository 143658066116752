@import "~@angular/material/theming";

$color-v: #00ffc0;
$color-p: #55c8ff;

$primary: (
  Main:#00ffc0,
  Light:#6dfff3,
  Dark: #00cb90,
  contrast: (
    Main: #000000,
    Light: #000000,
    Dark: #000000
  ));
$accent: (
  Main: #55c8ff,
  Light: #90fbff,
  Dark: #0097cc,
  contrast: (
    Main: #000000,
    Light: #000000,
    Dark: #000000
  ));
$warn: mat-palette($mat-red);

$custom-theme-primary: mat-palette($primary, Main, Light, Dark);
$custom-theme-accent: mat-palette($accent, Main, Light, Dark);

$candy-app-theme: mat-dark-theme((
  color: (
    primary: $custom-theme-primary,
    accent: $custom-theme-accent,
    warn: $warn
  )
));
$config: mat-get-color-config($candy-app-theme);

$foreground: map-get($config, foreground);
$background: map-get($config, background);
$textColor: mat-color($foreground, text);
$backgroundColor: mat-color($background, background);
$mutedColor: mat-color($foreground, disabled-text);
$warnColor: mat-color($warn);

$defaultGap: 16px;

$copyTextFontSize: 20px;

$defaultBoxShadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

// animation:
$defaultHoverTransitionTime: 0.3s;

// editor:
$groupLineColor: mat-color($foreground, disabled-text);
$editor-indent: 10px;
$editor-border-width: 2px;
$editor-offset: $editor-indent + $editor-border-width;

$defaultInputWidth: 210px;
$operationsInputWidth: $defaultInputWidth;
$fieldInputWidth: $defaultInputWidth;
$compareValueInputWidth: $defaultInputWidth;

$editorBreakpoint: 900px;
$smallerBreakpoint: 450px;


