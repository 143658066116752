/* You can add global styles to this file, and also import other style files */

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}


@import '~@angular/material/theming';
@include mat-core();
@import "variables";

$custom-typography: mat-typography-config(
  $body-1: mat-typography-level(16px, 24px, 300), // increase default font size to 16px to prevent auto zoom in iOS
  $body-2: mat-typography-level(16px, 24px, 500),
);

@include angular-material-theme($candy-app-theme);
@include mat-core($custom-typography);

.pv-warn {
  color: $warnColor;
}

.pv-muted {
  color: $mutedColor;
}


.pv-padding {
  padding: $defaultGap;
}

.pv-margin-r {
  margin-right: $defaultGap;
}

.pv-margin-r-small {
  margin-right: $defaultGap / 2;
}

.pv-margin-l {
  margin-left: $defaultGap;
}

.pv-margin-l-small {
  margin-left: $defaultGap / 2;
}

.pv-margin-b {
  margin-bottom: $defaultGap;
}

.pv-margin-t {
  margin-top: $defaultGap;
}

.pv-margin-t-small {
  margin-top: $defaultGap/2;
}

.pv-margin-v {
  margin-top: $defaultGap;
  margin-bottom: $defaultGap;
}

.pv-margin-h {
  margin-left: $defaultGap;
  margin-right: $defaultGap;
}

.pv-margin {
  margin: $defaultGap;
}

.pv-flex {
  display: flex;

  &.pv-flex-col {
    flex-direction: column;
  }

  &.pv-flex-center {
    align-items: center;
  }
}

.pv-flex-auto-grow {
  flex: 1 0 auto;
}

.pv-flex-no-grow {
  flex: 0 0 auto;
}

.pv-spacer {
  flex: 1 1 auto;
}

.pv-block {
  display: block;
}

.pv-full-width {
  width: 100%;
}

.pv-number-no-controls {
  // see https://thatstevensguy.com/programming/disable-arrows-on-number-inputs/
  &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  -moz-appearance: textfield;
}

.pv-hand:hover {
  cursor: pointer;
}

// default layout
.pv-main {
  max-width: 1200px;
}

// overwrites:
.mat-form-field-wrapper {
  padding-bottom: 0;
}

.pv-suffix-reset-top .mat-form-field-suffix {
  top: unset !important;
}

a.pv-link {
  color: inherit;
}

a.pv-text-link {
  text-decoration: none;
}

.pv-text-button {
  @extend .pv-hand;
  width: fit-content;
  color: $mutedColor;

  &:hover {
    color: unset;
  }

  transition: color $defaultHoverTransitionTime;
}

strong {
  font-weight: 500;
}

.pv-inline-icon {
  vertical-align: bottom;
}

.pv-box-shadow {
  box-shadow: $defaultBoxShadow;
}

.notification-error {
  color: $warnColor;
  font-weight: 500;
}

.pv-page-title {
  font-size: 36px !important;
  line-height: 46px !important;
}

.pv-font-form, .mat-tooltip {
  font-size: 16px !important;
}

/** increased font size for views with text focus (for forms, smaller font might be better on mobile..) */
.pv-copy {
  font-size: $copyTextFontSize;

  .mat-body-strong, .mat-list-item-content, .mat-button {
    font-size: $copyTextFontSize;
  }
}

.pv-table {
  border-collapse: collapse;

  th {
    @extend .mat-body-strong;
    text-align: left;
  }

  td {
    font-weight: 300;
  }

  td, th {
    padding: $defaultGap !important;

    &:first-child {
      padding-left: 0 !important;
    }

    &:last-child {
      padding-right: 0 !important;
    }
  }

  tr {
    border-bottom: 1px solid darken($textColor, 50%);
  }

  &.pv-table-vtop {
    td, th {
      vertical-align: top;
    }
  }
}

.pv-dl {
  dt {
    @extend .mat-body-strong;
  }

  dd {
    font-weight: 300;
  }
}

.pv-color-primary {
  color: $color-v;
}

.pv-color-secondary {
  color: $color-p;
}

.pv-button-large {
  padding: $defaultGap 2*$defaultGap !important;
  font-size: 32px !important;
}

.podio-icon {
  width: 20px;
  height: 20px;
}

.podio-icon-large {
  width: 40px;
  height: 40px;
}
